import axios from 'axios';

export const http = axios.create({
    // baseURL: "https://localhost:44397", // Local
    // baseURL: "http://legison-api", // Homologação
    baseURL: "https://api.sandbox.legison.pge.se.gov.br", // Integration/Sandbox
    // baseURL: "https://legison.pge.se.gov.br", // Produção
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
});

